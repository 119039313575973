export const contactPageStyles = (theme) => ({
  root: {
    width: "100%",
    margin: theme.spacing(3),
    backgroundColor: "#FFF",
    position: "relative"
  },
  title:{
    float:"left",
    display:"flow-root",
    marginLeft:"15%",
    marginTop:"5%"
  },
  content:{
    borderBottom: "3px solid",
    borderImageSource: "conic-gradient(red, orange, yellow, green, blue)",
    borderImageSlice: 1
  },
  bodycontentform:{
    display:"flow-root",
    height:"auto",
    "@media (min-width: 1024px  )": {
      width:"80%",
    },
    "@media (max-width: 1024px  )": {
      width:"100%",
    },
    border: '1px solid #000000',
    boxShadow:' 0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginBottom:15,
    paddingBottom:15
  },
  titlecontentform:{
    color: "#398801",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontFamily: 'Inter',
    fontSize: 35,
    fontStyle: "normal",
    paddingLeft:45,
    textAlign:"center"
  },
  contentform:{
    float:"left",
    paddingLeft:65,
    
  }
  ,stylecontentform:{
    display:"grid",
    width:"90%",
    padding:theme.spacing(3),
    margin: theme.spacing(1),
  },  
grid1:{
  "@media (max-width: 1024px  )": {
    maxWidth: "90%",
    margin: theme.spacing(5),
  },
},
grid2:{
  "@media (max-width: 1024px  )": {
    maxWidth: "90%",
    margin: theme.spacing(5),
  },
},
container:{
  "@media (max-width: 1024px  )": {
    display:'block'
  },
},
typography: {
  color:"#1976d2"
},

});
