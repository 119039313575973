import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneIcon from '@material-ui/icons/Phone';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../pages/routes";
import { appBarWithSidebarStyles } from "./appBarWithSidebar.styles";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import Sidebar from "./sidebar.component";

const useStyles = makeStyles(appBarWithSidebarStyles);

const AppBarWithSidebar = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  function Copyright() {
    return (<>
      <Typography variant="body2"  >
        <div><h3 className={classes.contentfooter}>Take Care Of Plants At Home, Contact Now</h3></div>
      </Typography>
      <div className={classes.title}>
        <span className={classes.information}><PhoneIcon /> Hotline: 0438 603 082 </span>
        <span className={classes.information}><FacebookIcon /> <a href="https://www.facebook.com/Hotgardens">Sunny Gardens</a></span>
        <span className={classes.information}><EmailIcon /> corolla16@hotmail.com</span>
      </div>
      <Typography variant="body2"  >
        <div><h4 className={classes.contentfooter}>Address : Frankston, VIC, Australia 3199</h4></div>
      </Typography>
    </>
    );
  }
  const Header = () => {
    return (<>
      <div className={classes.appBarSpacer} >
        <div className={classes.headertitle} >
          <Link className={classes.linkhover} to={ROUTES.HOME} > <span className={classes.headerlink}>HOME</span></Link>
          <Link className={classes.linkhover} to={ROUTES.SERVICE} ><span id="whatwedo" className={classes.headerlink}>WHAT WE DO</span></Link>
          <Link className={classes.linkhover} to={ROUTES.INTRODUCE} ><span id="whoweare" className={classes.headerlink}>WHO WE ARE</span></Link>
          <Link className={classes.linkhover} to={ROUTES.CONTACT} ><span id="contact" className={classes.headerlink}>CONTACT</span></Link>
        </div>
      </div>
    </>
    );
  }
  return (
    <div className={classes.root}>
      <Sidebar id="sidebar" style={{postion:"absolute"}} open={open} handleDrawerClose={handleDrawerClose} />
      <main className={classes.content}>
        <Box id="headerpage" pt={4} className={classes.header}>
          <Header />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon style={{ color: "#000",fontWeight:900 }} />
          </IconButton>
        </Box>
        <Box id="headerpageimage" pt={6} className={classes.imageheader}>
          <div className={classes.image} >
            <img alt="imageHeader" src='../../images/background1.jpg' style={{ width: "100%", height: "100%" }} />
          </div>
        </Box>
        <div className={classes.container}>
          <Grid container >
            {children}
          </Grid>

        </div>
        <Box pt={4} className={classes.copyright}>
          <Copyright />
        </Box>
      </main>
    </div>
  );
};



export default AppBarWithSidebar;
