import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { Route, BrowserRouter as Router, Switch, } from "react-router-dom";
import './App.css';
import AppBarWithSidebar from "./components/MainLayout/appBarWithSidebar.component";
import ContactPage from "./pages/contact.page";
import DashboardPage from "./pages/dashboard.page";
import IntroducePage from "./pages/introduce.page";
import * as ROUTES from "./pages/routes";
import ServicePage from "./pages/service.page";
import ServiceDetailPage from "./pages/serviceDetail.page";
import theme from "./styles/theme";

function App() {
  return (
    <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
              <AppBarWithSidebar >
                <Switch>
                  <Route exact path={ROUTES.HOME} component={DashboardPage} />
                  <Route exact path={ROUTES.INTRODUCE} component={IntroducePage} />
                  <Route exact path={ROUTES.CONTACT} component={ContactPage} />
                  <Route  path={ROUTES.SERVICE} component={ServicePage} />
                  <Route  path={ROUTES.SERVICEDETAIL} component={ServiceDetailPage} />
                </Switch>
              </AppBarWithSidebar>
              {/* <Redirect to={ROUTES.HOME} /> */}
          </div>
        </ThemeProvider>
      </Router>
  );
}

export default App;
