import {
  Divider, Drawer,
  IconButton, List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ContactsIcon from '@material-ui/icons/Contacts';
import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from "@material-ui/icons/ListAlt";
import clsx from "clsx";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../pages/routes";
import { sidebarStyles } from "./sidebar.styles";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
const useStyles = makeStyles(sidebarStyles);

const Sidebar = ({  open, handleDrawerClose }) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSelect = (event, index) => {
    setSelectedIndex(index);
  };
  const MENU_LIST_ITEMS =  [
    {
      name: "HOME",
      icon: <HomeIcon />,
      route: ROUTES.HOME
    },
    {
      name: "WHAT WE DO",
      icon:<ListAltIcon/>,
      route: ROUTES.SERVICE
    },
    {
      name: "WHO WE ARE",
      icon: <ContactsIcon />,
      route: ROUTES.INTRODUCE
    },
    {
      name: "CONTACT",
      icon: <ImportContactsIcon />,
      route: ROUTES.CONTACT
    },
    
    
    ];

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <MenuOpenIcon  />
        </IconButton>
      </div>
      <Divider />
      <List style={{paddingTop:0}} >
        {MENU_LIST_ITEMS.map((item, id) => (
          <ListItem
            button
            key={id}
            component={Link}
            to={item.route}
            selected={id === selectedIndex}
            onClick={event => handleSelect(event, id)}
            classes={{ selected: classes.selected }}
          >
            <ListItemIcon style={{color:"#000"}} >{item.icon}</ListItemIcon>
            <ListItemText
            style={{fontWeight:"600 !important"}}
              primary={item.name}
              
            />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Drawer>
  );
};

export default Sidebar;
