export const appBarWithSidebarStyles = theme => ({
  root: {
    display: "flex"
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "inherit",
    marginBottom: 0
  },
  container: {
    minHeight: "90vh",
    display: "flex",
  },
  copyright: {
    marginTop: "auto",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "#A2C523",
    color: "#000",
    overflow: "hidden",
    paddingBottom: 10,
    display: "grid"
  },
  contentfooter: {
    margin: 0,
    marginTop: 5,
    textAlign: "left",
    marginLeft: "17%"
  },
  information: {
    display: "flex",
    alignItems: "center",
    marginRight: 20
  },
  title: {
    flexGrow: 1,
    float: "left",
    marginLeft: "20%",
    marginTop: 10,

    "@media (min-width: 1024px )": {
      display: "flex",
    },
    "@media (max-width: 1023px  )": {
      fontSize: "0.8rem"
    }
  },
  header: {
    position: "relative",
    background: "#58bf6e",
    display: "flex",
    zIndex: 10,
    width:"100%",
    paddingLeft:"25%",
    "@media (max-width: 1023px  )": {
      paddingLeft:0,
    }
  },
  headerlink: {
    display: "flex",
    alignItems: "center",
    width: "max-content",
    height: "max-content",
    marginRight: 20,
    fontWeight: 800,
    fontFamily: 'Poppins',
    fontSize:"1rem",
    "&:hover": {borderBottom: "3px solid #000 !important"}
  },
  headertitle: {
    height:80,
    alignItems:"center",
    display: "flex",
    color: "#000",
    "@media (max-width: 1023px  )": {
      display: "none"
    }
  },
  linkhover: {
    textDecoration: "none"
    , color: "#000",
    
  },
  imageheader:{
    // position: "relative",
    display: "flex",
    zIndex: 10,
    width:"100%"
  },
  image:{
    height:200,
    width:"100%",
  }, menuButton: {
    float:"left",
    marginLeft: 175,
    "@media (max-width: 1024px  )": {
      display: "inline-block"
    },
      display: "none"
  },
  menuButtonHidden: {
    display: "none"
  },
});
