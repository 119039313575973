export const introducePageStyles = (theme) => ({
  root: {
    width: "100%",
    margin: theme.spacing(3),
    backgroundColor: "#FFF",
    position: "relative"
  },
  content:{
    borderBottom: "3px solid",
    borderImageSource: "conic-gradient(red, orange, yellow, green, blue)",
    borderImageSlice: 1
  },
  container:{
    margin: theme.spacing(3),
    padding:theme.spacing(5),
  },
  grid1:{
    "@media (max-width: 1024px  )": {
      maxWidth: "90%",
      margin: theme.spacing(3),
    },
  },
  grid2:{
    "@media (max-width: 1024px  )": {
      maxWidth: "90%",
      margin: theme.spacing(3),
      flexBasis: "100%",
    },
    padding:0
  },
});
