import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import TodayIcon from '@material-ui/icons/Today';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SERVICEDETAIL } from "../../pages/routes";
import ServiceDetailPage from "../../pages/serviceDetail.page";
import cardServiceStyle from "./cardService.style";
const useStyles = makeStyles(cardServiceStyle);
const CardService = (props) => {
  const [item, setItem] = useState();
  useEffect(() => {
    setItem(props?.item)
  }, [props.item])

  const classes = useStyles();
  return (<>
    <Link style={{textDecoration:"none"}} to={`${SERVICEDETAIL}/${item?.item.id}`} children={ServiceDetailPage}>
      <Paper className={classes.paper}>
        <Grid container wrap="nowrap" spacing={3}>
          <Grid style={{ padding: 0 }} className={classes.image} item>
            <img style={{ width: "100%", height: "100%" }} src={item?.item.thumbnail} alt={item?.item.title} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.content}>{item?.item.title}</Typography>
            <Typography className={classes.content1}><TodayIcon style={{ fontSize: "1rem", }} />{item?.item.createDate}</Typography>
            <Typography className={classes.content2}>{item?.item.subDescription}</Typography>
            <span className={classes.viewmore}><label style={{ textDecoration: "underline" }}>viewmore</label> <DoubleArrowIcon style={{ fontSize: "0.8rem", color: "#6599FF" }} /></span>
        </Grid> 
      </Grid>
    </Paper>
  </Link >    
    </>)
}
export default CardService;