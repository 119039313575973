import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { CallApi } from "../api/CallApi";
import CardService from "../components/Cards/cardService.component";
import { servicePageStyles } from "./servicePage.styles";
import { StyleHeaderBoder, StyleHeaderBoderNone, StyleHeaderDisplayBlock, StyleHeaderPostionRe } from "../components/MainLayout/stylewithId.style";

const useStyles = makeStyles(servicePageStyles);

const ServicePage = () => {
  useEffect(() => {
    StyleHeaderBoder("whatwedo");
    StyleHeaderBoderNone("whoweare");
    StyleHeaderBoderNone("contact");
    StyleHeaderPostionRe("headerpage");
    StyleHeaderDisplayBlock("headerpageimage");
  }, [])
  const [service, setService] = useState();
  const classes = useStyles();
  useEffect(() => {
    GetService();


  }, [])
  const GetService = async () => {
    setService({ "serviceEntities": [
      { "title": "Mowing Whipper Snipping", "thumbnail": "../../images/mowingwhippersnipping.jpg", "createDate": "18/05/2023", "subDescription": "Professional lawn and garden care service business that specialises  in providing top quality service for residential and commercial customers ...", "id": 1 }
      , { "title": "Hedge Trimming ", "thumbnail": "../../images/hedgetrimming.jpg", "createDate": "18/05/2023", "subDescription": "Professional lawn and garden care service business that specialises  in providing top quality service for residential and commercial customers ...", "id": 2 }, 
      { "title": "Laying Turf", "thumbnail": "../../images/layingturf.jpg", "createDate": "18/05/2023", "subDescription": "Professional lawn and garden care service business that specialises  in providing top quality service for residential and commercial customers ...", "id": 3 }, 
      { "title": "Garden Make Overs And Design", "thumbnail": "../../images/gardenmakeoversanddesign.jpg", "createDate": "18/05/2023", "subDescription": "Professional lawn and garden care service business that specialises  in providing top quality service for residential and commercial customers ...", "id": 4 }] })
    // await CallApi('GET', '/service')
    //   .then(response => response.json())
    //   .then(data => {
    //     setService(data);
    //   })
    //   .catch(data => {
    //     console.log(data);
    //   });
  }
  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="body2"  >
          <div><h1 className={classes.content}>WHAT WE DO</h1></div>
        </Typography>
        {service?.serviceEntities.map((item, id) => (
          <CardService key={id} item={{ item }} />
        ))}
      </Paper>
    </>
  );
};

export default ServicePage;
