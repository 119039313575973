export const StyleHeaderBoder = (Id)=>{

    document.getElementById(Id).style.borderBottom= "3px solid black";
   
}
export const StyleHeaderBoderNone = (Id)=>{

    document.getElementById(Id).style.borderBottom= "none";
   
}
export const StyleHeaderPostionRe = (Id)=>{
    document.getElementById(Id).style.position= "relative";
    document.getElementById(Id).style.background= "#58bf6e";
    
}
export const StyleHeaderPostionAb = (Id)=>{
    
    document.getElementById(Id).style.position= "absolute";
    document.getElementById(Id).style.background= "none";
}
export const StyleHeaderDisplayNone = (Id)=>{
    document.getElementById(Id).style.display= "none";
}
export const StyleHeaderDisplayBlock = (Id)=>{
    document.getElementById(Id).style.display= "contents";
}
