import { Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { StyleHeaderBoder, StyleHeaderBoderNone, StyleHeaderDisplayBlock, StyleHeaderPostionRe } from "../components/MainLayout/stylewithId.style";
import { introducePageStyles } from "./introducePage.styles";

const useStyles = makeStyles(introducePageStyles);

const IntroducePage = () => {
  useEffect(() => {
    StyleHeaderBoderNone("whatwedo");
    StyleHeaderBoder("whoweare");
    StyleHeaderBoderNone("contact");
    StyleHeaderPostionRe("headerpage");
    StyleHeaderDisplayBlock("headerpageimage");
  }, [])
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="body2"  >
          <div><h1 className={classes.content}>WHO WE ARE</h1></div>
        </Typography>
        <Grid className={classes.container} container spacing={2}>
          <Grid className={classes.grid1} item xs={8} >
            <img alt="introduce" style={{ width: "90%", height: "auto" }} src='../../images/background.jpg' />
          </Grid>
          <Grid className={classes.grid2} item xs={4}>
            <div className={classes.title}>
              <Typography variant="body2"  >
                <div><h3 style={{ textAlign: "left", fontSize: "large" }} >
                  <p className={classes.content} style={{ fontSize: "x-large", width: "max-content" }} >Sunny gardens</p> <label style={{ fontSize: "1.2rem" }}>is a professional lawn and garden care service business that specialises
                    in providing top quality service for residential and commercial customers
                    . <br /> The business is owned and operated by Joe, who has over 22 years of experience in horticulture
                    .
                    <br /> We take pride in our commitment  quality and customer satisfaction, and will go the extra mile  to exceed client expectations .
                    garden care service  provides expert pruning of roses and fruit trees and general garden care.<br />
                    Our services are :
                    <br />
                    * mowing whipper snipping
                    <br />
                    *hedge trimming
                    <br />
                    * laying turf
                    <br />
                    *garden make overs and design</label></h3></div>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default IntroducePage;
