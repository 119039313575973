const cardServiceDetailStyle = (theme) => ({
    root: {
      flexGrow: 1,
      overflow: 'hidden',
    //   padding: theme.spacing(0, 3),
    },
    paper: {
      maxWidth: "100%",
    //   margin: `${theme.spacing(3)}px auto`,
    //   padding: theme.spacing(2),
      borderTop:"0px solid #e0d4ce"
    },
    content:{
      marginLeft:10,
      textAlign:"left",
      color:"#398801",
      fontWeight:600,
      paddingLeft:"10%",
      marginTop:15
    },
    content1:{
      margin:"10px 0px 10px 25px",
      fontSize:"0.8rem",
      textAlign:"left"
      ,display:"flex",
      alignItems:"center"
    },
    content2:{
      marginLeft:10,
      textAlign:"left",
      paddingLeft:"10%",
      paddingTop:"2%",
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
      paddingRight:"10%",
      "@media (max-width: 500px  )": {
        paddingBottom: "15%"
    }
      
    },
    viewmore:{
      float:"right"
      ,display:"flex"
      ,marginRight:15,
      fontSize:"0.8rem",
      alignItems:"center",
      color:"#6599FF",
      marginTop:"5%",
      "&:hover": {fontSize:"120%"}
  
    },
    image:{
      width:"50%",
      marginLeft:"20%",
    },
    
  });
  export default cardServiceDetailStyle;