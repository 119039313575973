export const servicePageStyles = (theme) => ({
  root: {
    width: "100%",
    margin: theme.spacing(5),
    padding: theme.spacing(5),
    backgroundColor: "#FFF",
    position: "relative",
  },
  content: {
    borderBottom: "3px solid",
    borderImageSource: "conic-gradient(red, orange, yellow, green, blue)",
    borderImageSlice: 1
  }
});
