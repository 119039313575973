import { Button, Grid, Popover, TextField, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useState } from "react";
import { CallApiInsert } from "../api/CallApi";
import { StyleHeaderBoder, StyleHeaderBoderNone, StyleHeaderDisplayBlock, StyleHeaderPostionRe } from "../components/MainLayout/stylewithId.style";
import { contactPageStyles } from "./contact.styles";

const useStyles = makeStyles(contactPageStyles);

const ContactPage = () => {
  useEffect(() => {
    StyleHeaderBoderNone("whatwedo");
    StyleHeaderBoderNone("whoweare");
    StyleHeaderBoder("contact");
    StyleHeaderPostionRe("headerpage");
    StyleHeaderDisplayBlock("headerpageimage");
  }, [])
  const classes = useStyles();
  const [yourname, SetYourname] = useState();
  const [email, SetEmail] = useState();
  const [address, SetAddress] = useState();
  const [phone, SetPhone] = useState();
  const [content, SetContent] = useState();
  const [errorName, SetErrorName] = useState();
  const [errorEmail, SetErrorEmail] = useState();
  const [errorAddress, SetErrorAddress] = useState();
  const [errorPhone, SetErrorPhone] = useState();
  const [errorContent, SetErrorContent] = useState();
  let check = true;
  const request = { name:yourname,email: email, address: address, phone: phone, content: content }

  const [anchorEl, setAnchorEl] = useState(null);
  const [mess, setMess] = useState()
  const [loading, setLoading] = useState(false);
  const sendContact = (event) => {
    const checkError = validateContact(check);
    if (checkError) {
      CallSendContact(event);
    }
  }
  const CallSendContact = async (event) => {
    setLoading(true);

    await CallApiInsert(request, "POST", "/contact")
      .then(response => {
        setMess(response.data);
        setLoading(false);
        initData();
      })
      .catch(data => {
        setMess(data.error);
        setLoading(false);
      });
    setAnchorEl(event.target);
  }
  const validateContact = (check) => {
    if (yourname === "" || yourname === undefined) {
      SetErrorName("Name Required");
      check = false;
    } else {
      SetErrorName("");
    }
    if (email === "" || email === undefined) {
      SetErrorEmail("Email Required");
      check = false;
    } else {
      SetErrorEmail("");
    }
    if (address === "" || address === undefined) {
      SetErrorAddress("Address Required");
      check = false;
    } else {
      SetErrorAddress("");
    }
    if (phone === "" || phone === undefined) {
      SetErrorPhone("Phone Required");
      check = false;
    } else {
      SetErrorPhone("");
    }
    if (content === "" || content === undefined) {
      SetErrorContent("Content Required");
      check = false;
    } else {
      SetErrorContent("");
    }
    return check
  }
  const onChangeValueEmail = (event) => {
    SetEmail(event.target.value)
  }
  const onChangeValuePhone = (event) => {
    SetPhone(event.target.value)
  }
  const onChangeValueAddess = (event) => {
    SetAddress(event.target.value)
  }
  const onChangeValueContent = (event) => {
    SetContent(event.target.value)
  }
  const onChangeValueName = (event)=>{
    SetYourname(event.target.value)
  }
  const initData = () => {
    SetYourname("");
    SetEmail("");
    SetPhone("");
    SetAddress("");
    SetContent("");
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Paper className={classes.root}>

        <Typography variant="body2"  >
          <div><h1 className={classes.content}>CONTACT US</h1></div>
        </Typography>
        <Grid className={classes.container} container spacing={2}>

          <Grid className={classes.grid1} item xs={6} >
            <img alt="contact" style={{ width: "90%", height: "auto", marginTop: 75 }} src='../../images/contact.jpg' />
          </Grid>
          <Grid className={classes.grid2} item xs={6}>
            <div className={classes.title}>
              <Typography variant="body2"  >
                <div><h4 className={classes.content}>Address: Frankston, VIC, Australia 3199</h4></div>
              </Typography>
              <Typography variant="body2"  >
                <div><h4 style={{ float: "left" }} className={classes.content}>Hotline: 0438 603 082</h4></div>
              </Typography>
              <Typography variant="body2"  >
                <div><h4 style={{ float: "left" }} className={classes.content}>Email : corolla16@hotmail.com</h4></div>
              </Typography>
            </div>
            <div className={classes.bodycontentform}>
              <Typography variant="body2"  >
                <div><h1 className={classes.titlecontentform}>Fill in the form, and we’ll get back to you as soon as we can.</h1></div>
              </Typography>
              <div className={classes.stylecontentform}>
              <TextField
                  required
                  id="name"
                  label="Your Name"
                  variant="outlined"
                  style={{ marginBottom: 15 }}
                  value={yourname}
                  onChange={(event) => onChangeValueName(event)}
                  helperText={errorName}
                  type="email"
                />
                <TextField
                  required
                  id="email"
                  label="Email"
                  variant="outlined"
                  style={{ marginBottom: 15 }}
                  value={email}
                  onChange={(event) => onChangeValueEmail(event)}
                  helperText={errorEmail}
                  type="email"
                />
                <TextField
                  required
                  id="address"
                  label="Address"
                  variant="outlined"
                  style={{ marginBottom: 15 }}
                  value={address}
                  onChange={(event) => onChangeValueAddess(event)}
                  helperText={errorAddress}
                />
                <TextField
                  required
                  id="phone"
                  label="Phone"
                  variant="outlined"
                  style={{ marginBottom: 15 }}
                  value={phone}
                  onChange={(event) => onChangeValuePhone(event)}
                  helperText={errorPhone}
                />
                <TextField
                  required
                  id="content"
                  label="Content"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={content}
                  onChange={(event) => onChangeValueContent(event)}
                  helperText={errorContent}
                />
              </div>
              <Button disabled={loading} endIcon={<SendIcon />} loading={loading} style={{ fontWeight: 600 }} onClick={(event) => sendContact(event)} variant="contained" color="primary">
                Send
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography className={classes.typography}>{mess}</Typography>
              </Popover>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ContactPage;
