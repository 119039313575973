import { drawerWidth } from "../../styles/theme";

export const sidebarStyles = theme => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    position: "absolute",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    "@media (min-width: 1024px  )": {
      display: "none"
    },
    backgroundColor: "#0000004d",
    height:"auto",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    height:"auto",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    display:"none",
    
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#7D4427",
    fontWeight: 800
  }
});
