import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import cardServiceDetailStyle from "./cardServiceDetail.style";
const useStyles = makeStyles(cardServiceDetailStyle);
const CardServiceDetail = (props) => {
  const [item, setItem] = useState();
  useEffect(() => {
    setItem(props?.item)
  }, [props.item])

  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container wrap="nowrap" spacing={3}>
        <Grid item xs={12}>
          <h1 className={classes.content}>{item?.item.title}</h1>
          <div className={classes.image}><img style={{ width: "100%", height: "100%" }} src={item?.item.image} alt={item?.item.title} /></div>
          <Typography className={classes.content2}>{item?.item.description}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default CardServiceDetail;