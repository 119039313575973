import axios from "axios";
import { ENDPOINT } from "../env";


export const CallApi = ( method, url) => {
    const URL = ENDPOINT + url;
    const requestOptions = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Origin': '*', 'Credentials': true,
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        }
    };
    return fetch(URL, requestOptions)
}
export const CallApiUpload = (token,requestData, method, url) => {
    const URL = ENDPOINT + url;
    const request = new FormData();
     
      // Update the formData object
      request.append("image",requestData.image ===undefined?new File():requestData.image);
      request.append("name",requestData.name);
      request.append("price",requestData.price);
      request.append("typeId",requestData.typeId);
      request.append("id",requestData.id);
    return axios({
        method: method,
        url: URL,
        data: request,
              headers: {
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Origin': '*', 'Credentials': true,
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
            'Authorization':"Bearer "+ token}
      })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
}

export const CallApiInsert = (requestData, method, url) => {
  const URL = ENDPOINT + url;
 
  return axios({
      method: method,
      url: URL,
      data: requestData,
            headers: {
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Origin': '*', 'Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
            }
    })
      .then(function (response) {
        //handle success
       return response;
      })
      .catch(function (response) {
        //handle error
        return response;
      });
}
export const CallApiGet = ( method, url) => {
  const URL = ENDPOINT + url;
  return axios({
      method: method,
      url: URL,
            headers: {
          'Access-Control-Allow-Methods': '*',
          'Credentials': true,
          'Access-Control-Allow-Headers': 'Origin, X-Api-Key, X-Requested-With, Content-Type, Accept, Authorization',
          }
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
}

