import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CallApi } from "../api/CallApi";
import CardServiceDetail from "../components/Cards/cardServiceDetailPage.component";
import { SERVICE } from "./routes";
import { serviceDetailPageStyles } from "./serviceDetail.styles";
import { StyleHeaderBoder, StyleHeaderBoderNone, StyleHeaderDisplayBlock, StyleHeaderPostionRe } from "../components/MainLayout/stylewithId.style";

const useStyles = makeStyles(serviceDetailPageStyles);

const ServiceDetailPage = (props) => {
  useEffect(() => {
    StyleHeaderBoder("whatwedo");
    StyleHeaderBoderNone("whoweare");
    StyleHeaderBoderNone("contact");
    StyleHeaderPostionRe("headerpage");
    StyleHeaderDisplayBlock("headerpageimage");
  }, [])
  let history = useHistory();
  const classes = useStyles();
  const serviceId = props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1, props.location.pathname.length);
  const [serviceDetail, setServiceDetail] = useState();
  useEffect(() => {
    getServiceDetail();
  },[])
  const getServiceDetail = async () => {
    await CallApi('GET', `/service-detail/?serviceId=${serviceId}`)
      .then(response => response.json())
      .then(data => {
        setServiceDetail(data);
      })
      .catch(data => {
        console.log(data);
      });
  }
  const handleClick = () => {
    history.push(SERVICE)
  }
  return (
    <>
      <Paper className={classes.root}>
        <KeyboardBackspaceIcon onClick={handleClick} style={{ fontSize: 35, float: "left" }} />
        {serviceDetail?.serviceDetails.map((item, id) => (
          <CardServiceDetail key={id} item={{ item }} />
        ))}
      </Paper>
    </>
  );
};

export default ServiceDetailPage;
