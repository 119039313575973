import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { dashboardPageStyles } from "./dashboardPage.styles";
import { StyleHeaderBoderNone, StyleHeaderDisplayNone, StyleHeaderPostionAb } from "../components/MainLayout/stylewithId.style";


const useStyles = makeStyles(dashboardPageStyles);

const DashboardPage = () => {

  useEffect(() => {
    StyleHeaderBoderNone("whatwedo");
    StyleHeaderBoderNone("whoweare");
    StyleHeaderBoderNone("contact");
    StyleHeaderPostionAb("headerpage");
    StyleHeaderDisplayNone("headerpageimage");
  }, [])
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.root}>
        
      </Paper>
    </>
  );
};

export default DashboardPage;
